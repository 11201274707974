:root {
  --color-green-label: #87a173;
  --color-pink-text: #ff8080;
}

::selection {
  background-color: #f39f95;
  color: #fff;
}

html,
input,
input::placeholder {
  font-family: "Be Vietnam Pro", sans-serif;
}

html {
  font-size: 62.5%;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 50px;
}

*::-webkit-scrollbar-thumb {
  background-color: #f39f95;
  border-radius: 50px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #ca8c83;
}

/* Animation */
@keyframes fadeSlideUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: unset;
  }
}

@keyframes fadeOut {
  0% {
    width: 0;
    opacity: 0;
    /* transform: scale(0); */
  }
  100% {
    width: 100%;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes zoom-out {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (min-width: 1024px) {
  #device-message {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  #device-message {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    background: linear-gradient(to bottom, #fba1a1 8%, #fff 60%, #ff8080);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #device-message p {
    margin: 18px 20px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.7;
    text-align: center;
  }
}
