.motivational-speech {
  margin-bottom: 10px;
  padding: 16px 21px;
  background: linear-gradient(
    101.46deg,
    rgba(255, 255, 255, 0.7) 18.95%,
    rgba(255, 255, 255, 0.4) 74.93%
  );
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 22px;
  color: #000000;
}
