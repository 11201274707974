.cropper-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.cropper-image__container {
  padding: 16px 10px 0;
  width: 900px;
  height: 650px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.cropper-image__container .cropper-image__header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ced0d4;
  font-size: 1.6rem;
  color: #050505;
  text-align: center;
}

.cropper-image__container .cropper-image__body {
  padding-bottom: 40px;
  /* min-height: 100%; */
  flex: 1;
  overflow-y: auto;
}

/* .cropper-image__container .cropper-image__body::-webkit-scrollbar {
  width: 5px;
}

.cropper-image__container .cropper-image__body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 50px;
}

.cropper-image__container .cropper-image__body::-webkit-scrollbar-thumb {
  background-color: #f39f95;
  border-radius: 50px;
} */

.cropper-image__display-image-container {
  padding: 0 40px;
  width: 100%;
  height: 350px;
  position: relative;
  display: flex;
}

.cropper-image__display-image-container .cropper-image__instructor {
  padding: 8px 12px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.cropper-image__display-image-container .cropper-image__instructor i {
  margin-right: 8px;
}

.cropper-image__container .cropper-image__display-image {
  min-width: 692px;
  max-width: 880px;
  width: calc(var(--imgSizeX) + 40px);
  height: inherit;
  left: 50%;
  transform: translateX(-50%);
}

.cropper-image__edit-group {
  margin-top: 28px;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
}

.cropper-image__edit-group .range-slider__wrapper {
  padding: 0 45px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.cropper-image__edit-group .edit-group {
  display: flex;
  align-items: center;
}

.cropper-image__edit-group .range-slider__name {
  margin-right: 20px;
  display: block;
  font-size: 1.4rem;
}

.cropper-image__edit-group .range-slider {
  margin-bottom: 5px;
  /* width: 250px;
  height: 4px; */
  position: relative;
}

.cropper-image__edit-group .range-slider input {
  width: 100%;
  height: 4px;
  appearance: none;
  border-radius: 15px;
  background-color: #ced0d4;
  outline: none;
}

.cropper-image__edit-group .range-slider input::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 9;
  position: relative;
  background: #f39f95;
  appearance: none;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.cropper-image__edit-group .range-slider .progress-bar {
  width: 50%;
  height: 4px;
  border-radius: 3px;
  background: linear-gradient(to right, #1cd8d2, #93edc7);
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% + 2px));
  left: 0;
  z-index: 2;
  pointer-events: none;
}

.cropper-image__display-image-container .cropper-image__crop-area,
.cropper-image__display-image-container .cropper-image__crop-area::before,
.cropper-image__display-image-container .cropper-image__crop-area::after {
  border-color: #000;
}

.cropper-image__display-image-container .cropper-image__crop-area {
  color: rgba(0, 0, 0, 0.3);
}

.cropper-image__edit-group button {
  margin: 0 9px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
}

.cropper-image__container .top-close-btn {
  width: 35px;
  height: 35px;
  color: #7f7f7f;
  line-height: 35px;
  text-align: center;
  font-size: 1.8rem;
  position: absolute;
  top: 10px;
  right: 20px;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #e4e6eb;
  border-radius: 50%;
  transition: all 0.2s;
}

.cropper-image__container .top-close-btn i {
  position: relative;
  top: 1px;
}

.cropper-image__container .top-close-btn:hover {
  background-color: var(--color-pink-text);
  color: #fff;
}

.cropper-image .cropper-image__footer {
  border-top: 1px solid #ced0d4;
  margin-top: 10px;
  padding: 16px 16px 20px;
  display: flex;
  justify-content: flex-end;
}

.cropper-image .cropper-image__footer .btn {
  width: fit-content;
  margin: 0 4px;
  height: 36px;
  line-height: 36px;
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  letter-spacing: 1px;
}

.cropper-image .cropper-image__footer .close-btn {
  padding: 0 12px;
  background-color: transparent;
  color: var(--color-pink-text);
  transition: all 0.2s;
}

.cropper-image .cropper-image__footer .close-btn:hover {
  background-color: #e4e6eb;
}

.cropper-image .cropper-image__footer .complete-btn {
  padding: 0 40px;
  background-color: var(--color-pink-text);
  color: #fff;
}

.cropper-image__preview-avatar img {
  /* width: 200px;
  height: 200px; */
  /* border-radius: 50%; */
}
