.profile-page__wrapper .avatar-setting {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 153px calc(100% - 153px);
  grid-template-rows: 235px 46px 107px;
  background-color: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
}

.profile-page__wrapper .avatar-setting .wallpaper-wrapper {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.profile-page__wrapper .avatar-setting .wallpaper {
  width: 100%;
  height: 100%;
}

.profile-page__wrapper .avatar-setting .wallpaper-wrapper .edit-wallpaper {
  padding: 0 12px 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
  cursor: default;
}

.profile-page__wrapper
  .avatar-setting
  .wallpaper-wrapper
  .edit-wallpaper
  label {
  width: fit-content;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 8px 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  float: right;
}

.profile-page__wrapper
  .avatar-setting
  .wallpaper-wrapper
  .edit-wallpaper
  label:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.profile-page__wrapper
  .avatar-setting
  .wallpaper-wrapper
  .edit-wallpaper
  label
  i {
  font-size: 1.4rem;
}

.profile-page__wrapper
  .avatar-setting
  .wallpaper-wrapper
  .edit-wallpaper
  input {
  display: none;
}

.profile-page__wrapper .avatar-setting .wallpaper::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: block;
  transition: all 0.15s ease;
}

.profile-page__wrapper .avatar-setting .wallpaper:hover::before {
  background-color: rgba(255, 255, 255, 0.3);
}

.profile-page__wrapper .avatar-setting .avatar,
.profile-page__wrapper .avatar-setting .wallpaper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #e3e3e3;
}

.profile-page__wrapper .avatar-setting .avatar-wrapper {
  width: 153px;
  height: 153px;
  grid-column: 1 / 2;
  grid-row: 2 / 4;
  border-radius: 50%;
  border: 4px solid #fff;
  cursor: pointer;
  z-index: 9;
  position: relative;
}

.profile-page__wrapper .avatar-setting .avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile-page__wrapper .avatar-setting .avatar::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  display: block;
  transition: all 0.15s ease;
}

.profile-page__wrapper .avatar-setting .avatar:hover::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.profile-page__wrapper .avatar-setting .avatar-wrapper .edit-avatar input {
  display: none;
}

.profile-page__wrapper .avatar-setting .avatar-wrapper .edit-avatar label {
  font-size: 1.6rem;
  width: 36px;
  height: 36px;
  color: #e4e6eb;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px rgba(255, 255, 255, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  right: -4px;
  cursor: pointer;
}

.profile-page__wrapper
  .avatar-setting
  .avatar-wrapper
  .edit-avatar
  label:hover {
  background-color: rgba(94, 94, 94, 0.9);
}

.profile-page__wrapper .avatar-setting .account-info {
  margin-left: 5px;
  height: 100%;
  padding: 12px 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-page__wrapper .avatar-setting .account-info .username {
  /* flex: 1; */
  display: flex;
  justify-content: space-between;
}

.profile-page__wrapper .avatar-setting .account-info .username h3 {
  font-weight: 600;
  font-size: 2rem;
  /* line-height: 25px; */
  color: #87a173;
}

.profile-page__wrapper .avatar-setting .account-info .username button {
  width: fit-content;
  height: fit-content;
  font-size: 2.2rem;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: #87a173;
}

.profile-page__wrapper .avatar-setting .account-info .username button:hover {
  color: rgba(135, 161, 115, 0.6);
}

.profile-page__wrapper .avatar-setting .account-info .username input {
  padding-bottom: 6px;
  padding-right: 16px;
  width: fit-content;
  font-weight: 600;
  font-size: 2rem;
  outline: none;
  color: #87a173;
  border: none;
  border-bottom: 2px solid #87a173;
}

.profile-page__wrapper .avatar-setting .account-info .join-date {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 18px;
  color: #000;
}

.profile-page__wrapper .my-post .alternate-message {
  margin-top: 28px;
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: #7d7d7d;
}
