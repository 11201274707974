.note-page__container {
  margin-left: 14px;
}

.note-page__container .note {
  display: flex;
}

.note-page__container .note:nth-child(2n) {
  justify-content: end;
  transform: translateY(-20%);
}

.note-page__container .note:nth-child(2n + 1) {
  justify-content: start;
}
