.login-page__wrapper {
  padding-top: 60px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: top center;
  background-size: contain;
}

.login-page__wrapper .logo__wrapper {
  position: fixed;
  top: 16px;
  left: 20px;
}

.login-page__main {
  z-index: 9999;
}

.login-page__header-title {
  font-family: "iCiel Supa Mega Fantastic";
  font-size: 11rem;
  font-weight: lighter;
  line-height: 119px;
  color: var(--color-green-label);
  margin-bottom: 55px;
}

.login-page__login-section {
  margin-top: -19.5px;
}

.login-page__login-feature {
  --height-input-login: 56px;
  margin-top: 19.5px;
}

.login-page__login-feature label {
  color: var(--color-pink-text);
  font-size: 1.7rem;
  line-height: 22px;
}

.login-page__login-feature span {
  color: #bc2525;
}

.login-page__login-feature input {
  width: 343px;
  height: var(--height-input-login);
  font-size: 1.6rem;
  background: linear-gradient(
    101.46deg,
    rgba(255, 255, 255, 0.7) 18.95%,
    rgba(255, 255, 255, 0.4) 74.93%
  );
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  border: 2px solid #87a273;
  color: #87a173;
}

.login-page__login-feature input::placeholder {
  color: #87a273;
  font-weight: 100;
  line-height: 17px;
}

.login-page__forget-password {
  display: inline-block;
  margin-top: 14px;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 15px;
  color: #959595;
}

.login-page__login-btn-wrap {
  margin-top: 27.5px;
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
}

.login-page__login-btn-wrap button {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  background-color: #87a273;
  border: none;
}

.login-page__main .separate-section {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #87a273;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-page__main .separate-section::before,
.login-page__main .separate-section::after {
  content: "";
  position: absolute;
  width: 139.81px;
  border: 3px solid #87a273;
  border-radius: 10px;
}

.login-page__main .separate-section::before {
  left: 0;
}

.login-page__main .separate-section::after {
  right: 0;
}

.login-page__login-options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page__options-btn {
  margin: 21px 8px 36px;
  display: inline-block;
  padding: 19px 12.73px 15.28px 12px;
  background: linear-gradient(
    101.46deg,
    rgba(255, 255, 255, 0.7) 18.95%,
    rgba(255, 255, 255, 0.4) 74.93%
  );
  border: 1px solid #87a273;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.login-page__register-navigate {
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 22px;
  color: #4c4c4c;
}

.login-page__register-navigate a {
  display: inline-block;
  margin-left: 4px;
  color: #bc2525;
}

.login-page__login-feature.login-page__login-feature--password {
  position: relative;
}

.login-page__login-feature.login-page__login-feature--password
  .login-page__show-password {
  position: absolute;
  bottom: calc(var(--height-input-login) / 2);
  transform: translateY(calc(50% + 2px));
  right: 15px;
  cursor: pointer;
}

.login-page__options-btn:hover {
  background-color: #87a273;
}

.login-page__wrapper #form-login .error-label {
  margin-top: 16px;
  height: 20px;
  color: #ef2531;
  font-size: 1.3rem;
  font-weight: 400;
}

.login-page__wrapper #form-login .error-label i {
  margin-right: 4px;
}

.login-page__login-feature.login-page__login-feature--error input {
  border-color: #ef2531;
}
