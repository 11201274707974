.show-image__modal {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-image__modal .show-image__modal-container {
  position: relative;
}

.show-image__modal .image__wrapper {
  width: auto;
  height: calc(100vh - 120px);
}

.show-image__modal .image__wrapper img {
  width: 100%;
  height: 100%;
}

.show-image__modal .close-modal-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -50px;
  font-size: 3rem;
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
  transition: background-color 0.2s;
}

.show-image__modal .close-modal-btn:hover {
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
