@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "iCiel Supa Mega Fantastic";
  src: url(./assets/fonts/iCiel-Supa-Mega-Fantastic/iCielSupaMegaFantastic-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: "iCiel Supa Mega Fantastic";
  src: url(./assets/fonts/iCiel-Supa-Mega-Fantastic/iCielSupaMegaFantastic-Thin.otf);
  font-weight: lighter;
}

@font-face {
  font-family: "Fz Beloved Script";
  src: url(./assets/fonts/Fz-Beloved-Script/Fz\ Beloved\ Script.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Fz-Billie-Endria";
  src: url(./assets/fonts/Fz-Billie-Endria/Fz-Billie-Endria.ttf);
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
  display: inline-block;
}

ul {
  list-style-type: none;
}

body {
  margin: 0;
}
